<template>
  <div class="slider" v-if="isDataLoaded">
    <div
      class="slide-container"
      v-bind:style="[arrowCenterOptional ? { padding: 0 } : null]"
    >
      <div v-if="!isCenterMode" class="slide-container-bg"></div>

      <!-- <ssr-carousel
          loop
          :peek-left="isLimitSlide ? '0' : '40'"
          peek-right="40"
          :slides-per-page="isLimitSlide ? 3 : 5"
          v-model="page"
          :responsive="[
            {
              maxWidth: 1500,
              slidesPerPage: 4,
            },
            {
              maxWidth: 1200,
              slidesPerPage: 3,
            },
            {
              maxWidth: 900,
              slidesPerPage: 2,
            },
            {
              maxWidth: 600,
              slidesPerPage: 1,
            },
          ]"
        >
          <slide v-for="(item, index) in data" :key="index" :index="index">
            <a class="slider__item" :href="`/projects/${item.id}`">
              <div class="slider__img">
                <img
                  :src="item.image[0]"
                  alt="slide-content"
                  width="300"
                  height="270"
                />
              </div>
              <div class="slider__content">
                <div class="slider__content--title">{{ item.title }}</div>
                <div class="slider__content--tech">{{ item.tech }}</div>
                <span></span>
                <div class="slider__content--sub">{{ item.message }}</div>
                <button
                  type="button"
                  class="text see-all d-flex align-items-center ml-auto btn-slide-see"
                >
                  {{ button.seeMore }}
                  <svg
                    width="18"
                    height="12"
                    viewBox="0 0 20 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="services__item__detail-img"
                  >
                    <path
                      d="M1.33301 7C0.780723 7 0.333008 7.44772 0.333008 8C0.333008 8.55228 0.780723 9 1.33301 9V7ZM19.0123 8.70711C19.4029 8.31658 19.4029 7.68342 19.0123 7.29289L12.6484 0.928932C12.2579 0.538408 11.6247 0.538408 11.2342 0.928932C10.8436 1.31946 10.8436 1.95262 11.2342 2.34315L16.891 8L11.2342 13.6569C10.8436 14.0474 10.8436 14.6805 11.2342 15.0711C11.6247 15.4616 12.2579 15.4616 12.6484 15.0711L19.0123 8.70711ZM1.33301 9H18.3052V7H1.33301V9Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            </a>
          </slide>
        </ssr-carousel> -->

      <Flicking
        :options="{
          circular: true,
          align: isLimitSlide ? 'prev' : 'center',
          preventDefaultOnDrag: true,
        }"
        :plugins="plugins"
      >
        <div
          class="slide-wrapper__"
          v-for="(item, index) in data"
          :key="index"
          :index="index"
        >
          <router-link class="slider__item" :to="`/projects/${item.id}`">
            <div class="slider__img">
              <img
                v-lazy="item.image[0]"
                alt="slide-content"
                width="300"
                height="270"
              />
            </div>
            <div class="slider__content">
              <div class="slider__content--title">{{ item.title }}</div>
              <div class="slider__content--tech">{{ item.tech }}</div>
              <span></span>
              <div class="slider__content--sub">{{ item.message }}</div>
              <button
                type="button"
                class="text see-all d-flex align-items-center ml-auto btn-slide-see"
              >
                {{ button.seeMore }}
                <svg
                  width="18"
                  height="12"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="services__item__detail-img"
                >
                  <path
                    d="M1.33301 7C0.780723 7 0.333008 7.44772 0.333008 8C0.333008 8.55228 0.780723 9 1.33301 9V7ZM19.0123 8.70711C19.4029 8.31658 19.4029 7.68342 19.0123 7.29289L12.6484 0.928932C12.2579 0.538408 11.6247 0.538408 11.2342 0.928932C10.8436 1.31946 10.8436 1.95262 11.2342 2.34315L16.891 8L11.2342 13.6569C10.8436 14.0474 10.8436 14.6805 11.2342 15.0711C11.6247 15.4616 12.2579 15.4616 12.6484 15.0711L19.0123 8.70711ZM1.33301 9H18.3052V7H1.33301V9Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </router-link>
        </div>

        <span
          slot="viewport"
          class="flicking-arrow-prev"
          :style="{
            display: 'none',
          }"
        ></span>
        <span
          slot="viewport"
          class="flicking-arrow-next"
          :style="{
            display: 'none',
          }"
        ></span>
      </Flicking>
    </div>
    <div class="d-flex justify-content-center position-relative">
      <button
        for="prev"
        v-if="slickReady"
        class="d-flex align-items-center justify-content-center ml-3 rounded-circle slick-next position-relative rounded-circle"
        @click="previousSlide"
        style="
          width: 56px;
          height: 56px;
          left: 0;
          top: 0;
          transform: none;
          background-color: #ededed;
        "
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.75 15L6.25 15"
            stroke="#333333"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15 6.25L6.25 15L15 23.75"
            stroke="#333333"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>

      <button
        v-if="slickReady"
        class="d-flex align-items-center justify-content-center ml-3 rounded-circle slick-next position-relative rounded-circle"
        @click="nextSlide"
        style="
          width: 56px;
          height: 56px;
          right: 0;
          top: 0;
          transform: none;
          background-color: #ededed;
        "
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.25 15L23.75 15"
            stroke="#333333"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15 23.75L23.75 15L15 6.25"
            stroke="#333333"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Arrow } from '@egjs/flicking-plugins';
import '@egjs/flicking-plugins/dist/arrow.css';

export default {
  name: 'Carousel',
  data() {
    return {
      button: this.$t('button'),
      slickReady: false,
      page: 0,

      isDataLoaded: false,
      isCenterMode: false,
      speed: 400,
      plugins: [new Arrow()],
    };
  },
  props: {
    data: {
      type: Array,
      require: true,
    },
    arrowCenterOptional: {
      type: Boolean,
      default: undefined,
    },
    isLimitSlide: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    lang() {
      this.button = this.$t('button');
    },
    data(newData) {
      if (Array.isArray(newData) && newData.length > 0) {
        this.isDataLoaded = true;
      }
    },
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
  mounted() {
    this.slickReady = true;
    if (Array.isArray(this.data) && this.data.length > 0) {
      this.isDataLoaded = true;
    }
    this.checkScreenSize();
  },

  methods: {
    previousSlide() {
      // if (this.slickReady) {
      //   this.page -= 1;
      // }
      document.querySelector('.flicking-arrow-prev').click();
    },
    nextSlide() {
      document.querySelector('.flicking-arrow-next').click();
      // if (this.slickReady) {
      //   this.page += 1;
      // }
    },
    checkScreenSize() {
      const screenWidth = window.innerWidth;

      if (this.arrowCenterOptional) {
        this.isCenterMode = true;
        return;
      }
      this.isCenterMode = screenWidth <= 1024;
      this.speed = 200;
    },
  },
  created() {
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
};
</script>

<style lang="scss" scroped>
.card-panel {
  background: #000;
  height: 100px;
  width: 500px;
}
img {
  object-fit: cover;
}
.slick-next,
.slick-prev {
  border: none;
  &::before {
    display: none;
  }
}
.slider {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 35px;
  flex: 1;
  overflow: hidden;
  .slide-container {
    position: relative;
    padding: 80px 0;
    z-index: 1;
    .slide-container-bg {
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
      width: 70%;
      height: 100%;
      border-radius: 20px 0px 0px 20px;
      background: linear-gradient(
        116deg,
        #fc8945 4.12%,
        #fc7735 67.75%,
        #de5e12 108.3%
      );
    }
  }
  .slider__item {
    position: relative;
    width: 100%;
    height: 270px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 0;
    overflow: hidden;
    border-radius: 10px;
    text-decoration: none;
    transition: all 0.2s linear;
    /* margin: 0 5px; */
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 10px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
    }
    &:hover {
      .slider__img img {
        transform: scale(1.2);
      }
      .slider__content {
        top: 50%;
      }
    }
    &:hover::after {
      top: 0;
    }
  }
  .slider__img {
    position: relative;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      /* max-width: 300px; */
      transition: all 0.3s;
    }
  }
  .slider__content {
    position: absolute;
    border-radius: 10px;
    padding: 0 20px;
    width: 100%;
    top: 110%;
    transform: translateY(-50%);
    z-index: 5;
    transition: all 0.3s linear;
    .slider__content--title {
      overflow: hidden;
      color: #fff;
      text-overflow: ellipsis;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .slider__content--tech {
      color: #fff;
      font-size: 13px;
      font-style: normal;
      line-height: normal;
      text-transform: capitalize;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      margin: 2px 0;
    }
    .slider__content--sub {
      color: #fff;
      font-size: 16px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      margin-bottom: 18px;
    }
    span {
      display: block;
      width: 100%;
      height: 2px;
      background: #fff;
      margin: 12px 0;
    }
  }
  .btn-slide-see {
    background-color: #fc7735 !important;
    border-radius: 40px;
    padding: 8px 18px;
    width: max-content;
    border: none;
    gap: 8px;
    color: #fff;
  }
  .slider__control-change {
    position: absolute;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    top: 50%;
    left: -20px;
  }
  .disable {
    opacity: 0.5;
  }
  @media (max-width: 1536px) {
  }
  @media (max-width: 1280px) {
  }
  @media (max-width: 1024px) {
    .slide-container {
      padding: 0;
    }
    .slide-container::after {
      width: 100%;
      display: none;
    }
  }
  @media (max-width: 767.98px) {
    .slider__item {
      &::after {
        top: 0;
      }
    }
    .slider__content {
      top: 50%;
    }
  }
  @media (max-width: 576px) {
    .slide-container {
      &::after {
        display: none;
      }
    }
  }
  .slide-wrapper__ {
    width: 300px;
    margin: 0 5px !important;
  }
}
</style>
