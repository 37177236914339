var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isDataLoaded)?_c('div',{staticClass:"slider"},[_c('div',{staticClass:"slide-container",style:([_vm.arrowCenterOptional ? { padding: 0 } : null])},[(!_vm.isCenterMode)?_c('div',{staticClass:"slide-container-bg"}):_vm._e(),_c('Flicking',{attrs:{"options":{
        circular: true,
        align: _vm.isLimitSlide ? 'prev' : 'center',
        preventDefaultOnDrag: true,
      },"plugins":_vm.plugins}},[_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"slide-wrapper__",attrs:{"index":index}},[_c('router-link',{staticClass:"slider__item",attrs:{"to":`/projects/${item.id}`}},[_c('div',{staticClass:"slider__img"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.image[0]),expression:"item.image[0]"}],attrs:{"alt":"slide-content","width":"300","height":"270"}})]),_c('div',{staticClass:"slider__content"},[_c('div',{staticClass:"slider__content--title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"slider__content--tech"},[_vm._v(_vm._s(item.tech))]),_c('span'),_c('div',{staticClass:"slider__content--sub"},[_vm._v(_vm._s(item.message))]),_c('button',{staticClass:"text see-all d-flex align-items-center ml-auto btn-slide-see",attrs:{"type":"button"}},[_vm._v(" "+_vm._s(_vm.button.seeMore)+" "),_c('svg',{staticClass:"services__item__detail-img",attrs:{"width":"18","height":"12","viewBox":"0 0 20 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.33301 7C0.780723 7 0.333008 7.44772 0.333008 8C0.333008 8.55228 0.780723 9 1.33301 9V7ZM19.0123 8.70711C19.4029 8.31658 19.4029 7.68342 19.0123 7.29289L12.6484 0.928932C12.2579 0.538408 11.6247 0.538408 11.2342 0.928932C10.8436 1.31946 10.8436 1.95262 11.2342 2.34315L16.891 8L11.2342 13.6569C10.8436 14.0474 10.8436 14.6805 11.2342 15.0711C11.6247 15.4616 12.2579 15.4616 12.6484 15.0711L19.0123 8.70711ZM1.33301 9H18.3052V7H1.33301V9Z","fill":"white"}})])])])])],1)}),_c('span',{staticClass:"flicking-arrow-prev",style:({
          display: 'none',
        }),attrs:{"slot":"viewport"},slot:"viewport"}),_c('span',{staticClass:"flicking-arrow-next",style:({
          display: 'none',
        }),attrs:{"slot":"viewport"},slot:"viewport"})],2)],1),_c('div',{staticClass:"d-flex justify-content-center position-relative"},[(_vm.slickReady)?_c('button',{staticClass:"d-flex align-items-center justify-content-center ml-3 rounded-circle slick-next position-relative rounded-circle",staticStyle:{"width":"56px","height":"56px","left":"0","top":"0","transform":"none","background-color":"#ededed"},attrs:{"for":"prev"},on:{"click":_vm.previousSlide}},[_c('svg',{attrs:{"width":"30","height":"30","viewBox":"0 0 30 30","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M23.75 15L6.25 15","stroke":"#333333","stroke-width":"4","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M15 6.25L6.25 15L15 23.75","stroke":"#333333","stroke-width":"4","stroke-linecap":"round","stroke-linejoin":"round"}})])]):_vm._e(),(_vm.slickReady)?_c('button',{staticClass:"d-flex align-items-center justify-content-center ml-3 rounded-circle slick-next position-relative rounded-circle",staticStyle:{"width":"56px","height":"56px","right":"0","top":"0","transform":"none","background-color":"#ededed"},on:{"click":_vm.nextSlide}},[_c('svg',{attrs:{"width":"30","height":"30","viewBox":"0 0 30 30","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M6.25 15L23.75 15","stroke":"#333333","stroke-width":"4","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M15 23.75L23.75 15L15 6.25","stroke":"#333333","stroke-width":"4","stroke-linecap":"round","stroke-linejoin":"round"}})])]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }